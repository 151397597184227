import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import React from "react";
import {H2, H3, P} from "../components/typography";
import Layout from "../components/layout";


export default function PrivacyPage() {
    const { logo } = useStaticQuery(graphql`
        query PrivacyPageQuery {
          logo: file(relativePath: {eq: "logo.png"}) {
             image: childImageSharp {
              fluid(maxWidth: 512) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      `);
    return (
        <Layout>
            <div className="w-full max-w-6xl mx-auto py-16 px-2">
                <Img fluid={logo.image.fluid} className="mx-auto mb-8"/>
                <H2 className="uppercase text-center mb-4">Privacy Policy</H2>
                <P className="mb-8">
                    {`It is important to MASTERCOMP APPRAISAL SOLUTIONS LTD., its directors, offices, employees, agents and parent, subsidiaries and corporate affiliates (collectively, “QuickComp”, “”us”, “our”, or “we”) to help any individual who is a customer, prospective customer, vendor, or other individual member of the public whose Personal Information (as defined herein) is within our possession or control (collectively, “you” or “your”) retain your privacy in connection with any information we may receive from them.`}
                </P>
                <P>
                    {`This Platform Privacy Policy is incorporated into and forms part of the terms of service, service agreement, or terms of use of any website, application or other platform operated or provided by QuickComp (collectively, the “Platform”). Our current Platform Service Agreement & Terms of Use can be viewed here.`}
                </P>
                <P>
                    {`By accepting services from us, visiting the Website, or providing us with Data (as herein defined) or your Personal Information you accept this Privacy Policy and any future revisions hereto.`}
                </P>
                <P>
                    {`We believe your business is yours and no one else's. Your privacy is important to you and to us. Accordingly, we will protect the information you share with us. To protect your privacy, QuickComp follows universal principles in accordance with worldwide practices for customer privacy and data protection, as follows:`}
                </P>
                <P>
                     <ul className="list-disc pl-8 mt-2">
                        <li>{`We will not sell or give away your name, Data, mailing address, phone number, email address or any other information to anyone. `}</li>
                        <li>{`We will use the best available security measures to protect your information from unauthorized users.`}</li>
                    </ul>
                </P>
                <P className="mb-8">
                    {`Note that the Platform may contain links to other sites not created by us and not under our control (“Third-Party Websites”). These links are provided for convenience only, and we make no representations or warranties about the privacy practices of Third-Party Websites. We are not responsible for the content on any Third-Party Websites. When visiting a Third-Party Website, you do so entirely at your own risk, and we encourage you to refer to their privacy policies to best understand how they may treat your personal information. It is your responsibility to take all protective measures to guard against viruses or other destructive devices or programs.`}
                </P>
                <section className="mb-8 ">
                    <H3 className="uppercase mb-4">
                        Notice
                    </H3>
                    <P>
                        {`We will ask you when we need information that personally identifies you, provides personal account data, financial information, or allows us to contact you (collectively, “Personal Information”). Generally, this information is requested when you create an account on the Platform or when you register for paid services from us. We use your Personal Information for the following purposes:`}
                        <ul className="list-disc pl-8 mt-2">
                            <li>{`To make the Platform easier for you to use by not having to enter information more than once.`}</li>
                            <li>{`To alert you to product upgrades, special offers, updated information and other new services we may offer from time to time.`}</li>
                        </ul>
                    </P>
                    <P>
                        {`The Platform is designed to store, manage, analyze and interpret data provided by you in the form of appraisals and related information (collectively, the “Data”). We will collect only the information needed to provide our products and services to you, and we will only collect information from you in ways that are transparent, lawful, and in good faith.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        CONSENT AND USE OF DATA AND PERSONAL INFORMATION
                    </H3>
                    <P>
                        {`If you choose not to register or provide Personal Information or Data (collectively referred to herein as “Your Information”), you can still use some areas of the Website, however, you will not be able to access areas that require registration, including applications.`}
                    </P>
                    <P>
                        {`If you decide to register, you will be able to select the kinds of information you want to receive from us by subscribing to various services, like our electronic newsletters. If you do not want us to communicate with you about other offers regarding QuickComp products, programs, events, or services by email, postal mail, or telephone, you may select the option(s) stating that you do not wish to receive marketing messages from QuickComp.`}
                    </P>
                    <P>
                        {`By using the Platform, accepting services from us, or otherwise providing us with Your Information, you will be deemed to consent to our use of Your Information in connection with the following:`}
                        <ul className="list-disc pl-8 mt-2">
                            <li>{`To identify or contact you, respond to your inquiries, and otherwise maintain a business relationship with you; `}</li>
                            <li>{`To provide services you may request from time to time; `}</li>
                            <li>{`To provide you with information about our services; `}</li>
                            <li>{`To bill, process, and collect payment from you for services rendered; `}</li>
                            <li>{`To administer and manage our day-to-day activities; `}</li>
                            <li>{`To assist in the investigation of fraudulent or unlawful activity; `}</li>
                            <li>{`To request participation in user surveys; `}</li>
                            <li>{`To comply with applicable legal and regulatory requirements; `}</li>
                            <li>{`To analyze Platform usage and engagement patterns; and `}</li>
                            <li>{`To fulfill any other reasonable purpose permitted under applicable legislation or regulation.  `}</li>
                        </ul>
                    </P>
                    <P>
                        {`In addition to the foregoing, in the course of working with us, you may provide us with Personal Information from a broad range of activities connected with products and services we may provide from time to time. This may include feedback from you on the quality, content, and usefulness of the Website and our products and services. Any time you communicate with us by email or by other means, we may retain copies of that feedback or correspondence in order to provide you with the best service possible. By communicating with us, you are deemed to consent to such document retention.`}
                    </P>
                    <P>
                        {`We recognize that your Data is confidential in nature, and we will not disclose your Data unless legally compelled to do so.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        DISCLOSURE
                    </H3>
                    <P>
                        {`We will not use or disclose Your Information for purposes other than those for which it was collected, unless as required by law, or unless we have first obtained your express consent (by verbal, written, or electronic agreement), or unless such disclosure is required by the governing jurisdiction under which one or more of our service providers operates. In the event of a proposed sale of our business or some or all of our assets, or a merger or amalgamation of QuickComp with another company (a “Transaction”), we may disclose your Personal Information to specified third parties including prospective purchasers or partners and their advisors and representatives. Prior to such disclosure, we shall obtain non-disclosure agreements from any potential recipient of your Personal Information, requiring them to return or destroy all Personal Information so disclosed in the event that the Transaction shall fail to complete. You are deemed to consent to the disclosure of your Personal Information for the purposes of our negotiation of a Transaction. Note that Personal Information disclosed in connection with a Transaction will be provided in an aggregated form only, and in a way that will not be personally identifiable.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        ANALYTIC TOOLS / COOKIES
                    </H3>
                    <P>
                        {`We monitor usage of the Website to gauge usership patterns and improve our systems. We may from time to time utilize analytic tools which may collect certain information by automated means using technologies such as cookies, web beacons, pixels, browser analysis tools, server logs, and mobile identifiers.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        ACCESS
                    </H3>
                    <P>
                        {`We will provide you with the means to ensure that Your Information is correct and current. You may review and update this information at any time on mastercomp.io or any related QuickComp mobile application. There, you can:`}
                        <ul className="list-disc pl-8 mt-2">
                            <li>{`View and edit Personal Information you have already given us.`}</li>
                            <li>{`View and edit your Data uploads. `}</li>
                        </ul>
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        SECURITY & ACCOUNTABILITY
                    </H3>
                    <P>
                        {`QuickComp has taken measures to protect the security of Your Information and to ensure that your choices for its intended use are honored. We take precautions to protect Your Information from loss, misuse, unauthorized access or disclosure, alteration, or destruction.`}
                    </P>
                    <P>
                        {`You also have a significant role in protecting Your Information. No one can see or edit Your Information without knowing your user name and password, so do not share these with others.`}
                    </P>
                    <P>
                        {`We are responsible for all Personal Information and Data in our control. We will at all times designate one or more persons within our organization with the responsibility of ensuring we are compliant with applicable privacy laws and regulations in relation to your Personal Information.`}
                    </P>
                    <P>
                        {`We are committed to providing you with understandable and easily available information about our policy and practices related to management of your personal information. This policy and any related information is available at all times on the Platform or on request.`}
                    </P>
                </section>
                <section className="mb-8">
                    <H3 className="uppercase mb-4">
                        ACCESS
                    </H3>
                    <P>
                        {`You can request access to Your Information stored by us. Upon receiving such a request, we will: (i) obtain satisfactory proof to confirm your identity; (ii) inform you about what type of information we have on record or in our control, how such information is used and to whom the information may have been disclosed; (iii) provide you with access to your information so you can review and verify the accuracy and completeness and request changes to the information; and (iv) make any necessary updates to your information.`}
                    </P>
                    <P>
                        {`If you have questions or concerns about this policy, please contact us at `} <a href="mailto:hello@quickcomp.io">hello@quickcomp.io</a>.
                    </P>
                </section>
            </div>
        </Layout>
    )
}
